import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import './cadeau.css'

function Cadeau() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "title_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      gift: file(relativePath: { eq: "gift.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="gift">
      <SEO title="Le bô Cado" />
      <GatsbyImage
        image={data.lights.childImageSharp.gatsbyImageData}
        className="page-title-lights"
        alt="Dessin d'une guirlande de lumières"
      />
      <h1 className="page-title">
        <span className="first-line">Le</span>
        <br />
        <span className="middle-line">BEAU CADO</span>
        <br />
        <span className="last-line">(c&apos;est pas un poney)</span>
      </h1>

      <p className="paragraph">
        Vous nous avez déjà bien gâtés l&apos;année dernière, et comme on a pas
        assez eu l&apos;occation de le dire...&nbsp;<b>Merci beaucoup !</b>
      </p>

      <p className="paragraph">
        Grâce à vous, nous avons déjà pu faire un magnifique road-trip en
        Corse.&nbsp;Du coup plus de cagnotte, juste des remerciements !
      </p>

      <GatsbyImage
        image={data.gift.childImageSharp.gatsbyImageData}
        alt="Dessin d'un bateau de chats pirates naviguant sur les eaux agîtées de la baignoire"
        className="gift-image"
      />

      {/* <a
        href="https://paypal.me/pools/c/8oei3HNv4C"
        target="_blank"
        rel="noopener noreferrer"
        className="paypal-btn"
      >
        Participer au cado
      </a> */}

      <Footer />
    </MainLayout>
  )
}

export default Cadeau
